<template>
  <section class="HomeIconicDestinations">
    <div class="HomeIconicDestinations-headerBlock">
      <ai-separator class="HomeIconicDestinations-verticalSeparator" vertical />

      <ai-typo
        class="HomeIconicDestinations-title"
        as="h2"
        variant="heading-02">
        {{ title }}
      </ai-typo>

      <ai-typo
        class="HomeIconicDestinations-subTitle"
        as="p"
        variant="paragraph-02-regular">
        {{ subtitle }}
      </ai-typo>
    </div>
    <ul
      id="HomeIconicDestinations"
      ref="destinationsBlock"
      class="HomeIconicDestinations-destinationsBlock">
      <ai-destination
        v-for="(destination, destinationIndex) in destinationLoaded"
        :key="destination.id"
        class="HomeIconicDestinations-item"
        :class="{
          'HomeIconicDestinations-item--third': gridDisplay === 'third',
          'HomeIconicDestinations-item--half': gridDisplay === 'half',
          'HomeIconicDestinations-item--custom': gridDisplay === 'custom',
        }"
        :aria-posinset="destinationIndex + 1"
        :title="destination.title ?? ''"
        :hotel-number="destination.countInHotels"
        :link="
          localePath({
            name: 'search',
            query: {
              destination: JSON.stringify({
                text: destination.title,
                type: SearchDestinationResultType.Country,
              }),
            },
          })
        "
        :picture="destination.destinationPicture"
        lazy-load-image
        full-link />
    </ul>
    <div ref="destinationsButton" class="HomeIconicDestinations-buttonBlock">
      <ai-button
        v-if="items.length > 5"
        class="HomeIconicDestinations-button"
        :label="buttonLabel"
        variant="tertiary"
        :icon-right="buttonIcon"
        aria-controls="HomeIconicDestinations"
        :aria-expanded="expanded"
        @click="toggleExpanded" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { DestinationFragment } from '~~/domains/graphql';
import { SearchDestinationResultType } from '~~/domains/graphql';
import type { IconName } from '~~/domains/ux/atoms/AiIcon/types';

type Props = {
  title: string;
  subtitle: string;
  items: DestinationFragment[];
};

const BASE_ITEM_LENGTH = 5;

const props = defineProps<Props>();

const { t } = useI18n();
const itemLength = ref<number>(BASE_ITEM_LENGTH);
const destinationLoaded = computed(() =>
  props.items.slice(0, itemLength.value),
);
const expanded = ref<boolean>(false);

const buttonIcon = computed<IconName>(() =>
  expanded.value ? 'chevron' : 'chevron-down',
);
const buttonLabel = computed<string>(() =>
  expanded.value
    ? t('homepage.iconicDestinations.buttonLabelLess')
    : t('homepage.iconicDestinations.buttonLabelAll'),
);

const destinationsBlock = ref<HTMLDivElement | null>(null);
const destinationsButton = ref<HTMLDivElement | null>(null);

const toggleExpanded = () => {
  if (!expanded.value) {
    itemLength.value = props.items.length;
    if (destinationsButton.value) {
      destinationsButton.value.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }

    nextTick(() => {
      const firstExpandedChild = destinationsBlock.value?.children.item(
        BASE_ITEM_LENGTH,
      ) as HTMLElement;

      const expandedChildLink = firstExpandedChild?.querySelector(
        '.AiLink',
      ) as HTMLAnchorElement;
      expandedChildLink?.focus();
    });
  } else {
    itemLength.value = BASE_ITEM_LENGTH;
    if (destinationsBlock.value) {
      destinationsBlock.value.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }
  expanded.value = !expanded.value;
};

const gridDisplay = computed(() => {
  if (itemLength.value % 8 === 0) {
    return 'custom';
  }

  if (itemLength.value % 3 === 0) {
    return 'third';
  }

  if (itemLength.value % 2 === 0) {
    return 'half';
  }

  return 'default';
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/colors';

.HomeIconicDestinations {
  padding-top: constants.$padding-04;
}

.HomeIconicDestinations-headerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.HomeIconicDestinations-verticalSeparator {
  @include mixins.rem-fallback(height, 80);
}

.HomeIconicDestinations-title {
  margin-top: constants.$margin-04;
}

.HomeIconicDestinations-subTitle {
  margin-top: constants.$margin-02;
  margin-bottom: constants.$margin-04;
}

.HomeIconicDestinations-destinationsBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (mq.$from-medium) {
    flex-direction: row;
  }
}

.HomeIconicDestinations-buttonBlock {
  display: flex;
  justify-content: center;
  padding: constants.$padding-04 0;
}

.HomeIconicDestinations-item {
  flex-basis: 50%;
  flex-grow: 1;

  &:nth-child(5n + 3),
  &:nth-child(5n + 4),
  &:nth-child(5n + 5) {
    flex-basis: calc(100% / 3);
  }

  &--third {
    flex-basis: calc(100% / 3);
  }

  &--half {
    flex-basis: 50%;
  }

  &--custom {
    flex-basis: calc(100% / 3);
    flex-grow: 1;

    &:nth-child(5n + 4),
    &:nth-child(5n + 5) {
      flex-basis: 50%;
    }
  }
}
</style>
