<template>
  <div class="HomePillarAccordion">
    <ai-image
      class="HomePillarsAccordion-img"
      :src="currentItem.picture?.src"
      :src-set="
        // eslint-disable-next-line vue/no-deprecated-filter
        currentItem.picture?.srcSet as string | undefined
      "
      :alt="currentItem.picture?.alt ?? ''"
      :xs="85"
      :sm="90"
      loading="lazy" />
    <ai-simple-accordion
      v-model="currentAccordion"
      class="HomePillarsAccordion-accordion"
      :title="$t('homepage.collectionExperienceTitle')"
      :items="items" />
  </div>
</template>

<script setup lang="ts">
import type { PillarFragment } from '~~/domains/graphql';
type Props = {
  items: PillarFragment[];
};

const props = defineProps<Props>();

const currentAccordion = ref<number>(0);

const currentItem = computed(() => props.items?.[currentAccordion.value]);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/colors';

.HomePillarsAccordion-accordion {
  position: relative;
  background: constants.$linear-gradient-stratos;
  padding: constants.$padding-07 0 constants.$padding-02 0;

  @media (mq.$from-medium) {
    padding: constants.$padding-06 0 246px 0;
    height: 100vh;

    background: linear-gradient(
      108.89deg,
      rgba(0, 0, 0, 0.72) 20.05%,
      rgba(0, 0, 0, 0.72) 46.06%,
      rgba(0, 0, 0, 0) 78.84%
    );
  }
}

.HomePillarsAccordion-img {
  display: none;

  @media (mq.$from-medium) {
    display: initial;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: -1;
  }
}
</style>
