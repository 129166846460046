<template>
  <div :id="id" class="Layout">
    <div class="Layout-section" />
    <div class="HomeExperienceCategoriesCarousel">
      <div class="HomeExperienceCategoriesCarousel-descriptionBlock">
        <ai-typo as="p" variant="featured">
          {{ bigTitle }}
        </ai-typo>
        <div class="HomeExperienceCategoriesCarousel-separatorContent">
          <ai-separator
            class="HomeExperienceCategoriesCarousel-separator"
            vertical
            :margin="4" />
        </div>

        <ai-typo
          v-if="!isSmallDevice"
          class="HomeExperienceCategoriesCarousel-subtitle"
          as="h2"
          variant="heading-02">
          {{ title }}
        </ai-typo>
        <ai-typo v-if="!isSmallDevice" as="p" variant="paragraph-02-regular">
          {{ subtitle }}
        </ai-typo>
      </div>
    </div>
  </div>
  <ai-carousel-overflow
    v-model="currentStep"
    access-id="HomeExperienceCategoriesCarousel-carousel"
    class="HomeExperienceCategoriesCarousel-carousel"
    :carousel-only="!isSmallDevice"
    arrows-right-position="7%"
    :items="items"
    :mobile-button="isSmallDevice"
    button-position="center"
    :title="title"
    :description="subtitle"
    :slide-width="slideWidth"
    :aria-label="$t('accessibility.description.hotelOffersAndPackagesCarousel')"
    :accessibility-label="title"
    show-cta
    :component="HomeExperienceCategoriesCarouselItem" />
</template>

<script setup lang="ts">
import HomeExperienceCategoriesCarouselItem from '../HomeExperienceCategoriesCarousel/HomeExperienceCategoriesCarouselItem.vue';

import type { ExperienceCategory } from '~~/domains/graphql';
import AiCarouselOverflow from '~~/domains/ux/organisms/carousels/AiCarouselOverflow.vue';
import type { CarouselOverflowItemProps } from '~~/domains/ux/organisms/carousels/types';

interface Props {
  id: string;
  bigTitle: string;
  title: string;
  subtitle: string;
  experienceCategories: ExperienceCategory[];
}

const props = defineProps<Props>();

const { t } = useI18n();
const localePath = useLocalePath();

const items = computed<CarouselOverflowItemProps[]>(() =>
  (props.experienceCategories ?? []).map(
    experienceCategory =>
      ({
        ctaLabel: t('homepage.experiencesCarousel.button'),
        ctaUrl: localePath({
          name: 'search',
          query: {
            experienceCategoryId: experienceCategory.id,
          },
        }),
        description:
          t('homepage.hotel&ResortsLabel', {
            hotelsAndResortsCount: experienceCategory.countInHotels,
          }) ?? '',
        key: (experienceCategory.id || experienceCategory.title) ?? '',
        lazyLoadImage: true,
        pictogramsUrl: experienceCategory.logo?.src ?? '',
        picture: experienceCategory.carouselImage ?? null,
        title: experienceCategory.title ?? '',
      }) as CarouselOverflowItemProps,
  ),
);

const isSmallDevice = useMatchMediaQuery('tablet');
const slideWidth = computed(() => (isSmallDevice.value ? 0.75 : 0.3));

const currentStep = ref<number>(1);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/mq';

.HomeExperienceCategoriesCarousel {
  padding-top: constants.$padding-07;
}

.HomeExperienceCategoriesCarousel-descriptionBlock {
  text-align: center;
}

.HomeExperienceCategoriesCarousel-separatorContent {
  display: flex;
  justify-content: center;
  margin: constants.$margin-02 0;

  @media (mq.$from-medium) {
    margin: constants.$margin-04 0;
  }
}
.HomeExperienceCategoriesCarousel-separator {
  @include mixins.rem-fallback(height, 80);
}

.HomeExperienceCategoriesCarousel-subtitle {
  margin-bottom: constants.$margin-01;
}

.HomeExperienceCategoriesCarousel-carousel {
  overflow-x: hidden;
  text-align: center;
  @media (mq.$from-medium) {
    overflow-x: initial;
  }
}

.AiCarouselOverflow-previous.AiCarouselOverflow-previousSpacer {
  padding-bottom: 0;

  @media (mq.$from-medium) {
    padding-bottom: calc(4.8rem + 9.6rem);
  }
}

.HomeExperienceCategoriesCarousel-mobileButton {
  margin-top: constants.$margin-03;

  @media (mq.$from-medium) {
    display: none;
  }
}
</style>
