<template>
  <ai-carousel-overflow
    ref="HomeSpecialPackagesCarousel-carousel"
    v-model="currentStep"
    access-id="HomeSpecialPackagesCarousel-carousel"
    class="HomeSpecialPackagesCarousel-carousel"
    :carousel-only="false"
    :items="items"
    :title="$t('homepage.specialPackage.title')"
    :subtitle="$t('homepage.specialPackage.subTitle')"
    :description="description ?? ''"
    :slide-width="slideWidth"
    :aria-label="$t('accessibility.description.homeSpecialOffersCarousel')"
    :accessibility-label="$t('homepage.specialPackage.title')"
    :component="HomeSpecialPackagesCarouselItem"
    :link="allOffersLink"
    :cta-label="$t('hotel.offersAndPackages.cta')">
    <template #footer>
      <div class="HomeSpecialPackagesCarousel-button">
        <ai-carousel-button
          :accessibility-label="$t('homepage.specialPackage.title')"
          @left-click="onPreviousSlide"
          @right-click="onNextSlide" />
      </div>
    </template>
  </ai-carousel-overflow>
</template>

<script setup lang="ts">
import HomeSpecialPackagesCarouselItem from '../HomeSpecialPackagesCarousel/HomeSpecialPackagesCarouselItem.vue';

import type { OfferAndPackage } from '~~/domains/graphql';
import AiCarouselOverflow from '~~/domains/ux/organisms/carousels/AiCarouselOverflow.vue';
import type { CarouselOverflowItemProps } from '~~/domains/ux/organisms/carousels/types';

interface Props {
  description?: string;
  specialOffers: OfferAndPackage[];
  allOffersLink?: string | null;
}

const props = defineProps<Props>();
const { t } = useI18n();

const items = computed<CarouselOverflowItemProps[]>(() =>
  (props.specialOffers ?? []).map(
    specialOffer =>
      ({
        ctaLabel: t('hotel.offersAndPackages.item.cta'),
        description: specialOffer.description ?? '',
        detailLink: specialOffer.detailLink,
        key: specialOffer.id ?? '',
        picture: specialOffer.picture ?? null,
        title: specialOffer.sectionTitle ?? '',
        subtitle: specialOffer.title ?? '',
      }) as CarouselOverflowItemProps,
  ),
);

const isSmallDevice = useMatchMediaQuery('desktop');
const slideWidth = computed(() => (isSmallDevice.value ? 0.75 : 0.5));

const currentStep = ref<number>(1);

const onPreviousSlide = () => {
  if (currentStep.value === 1) {
    currentStep.value = items.value.length;
  } else {
    currentStep.value--;
  }
};

const onNextSlide = () => {
  if (currentStep.value === items.value.length) {
    currentStep.value = 1;
  } else {
    currentStep.value++;
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/constants';
.HomeSpecialPackagesCarousel-carousel {
  position: relative;
  margin-top: constants.$margin-04;

  @media (mq.$from-medium) {
    margin-top: constants.$margin-07;
  }
}

.HomeSpecialPackagesCarousel-button {
  position: absolute;

  top: calc(50% - (constants.$margin-04 + constants.$margin-02));
  right: calc(20% - constants.$margin-02);

  @media (mq.$from-medium) {
    display: none;
  }
}
</style>
