<template>
  <div class="AiAccordion">
    <div class="Layout">
      <div class="Layout-section">
        <grid-item class="AiAccordion-itemContent" offset-md="1" md="7" sm="4">
          <ai-typo
            class="AiAccordion-title"
            as="h2"
            variant="overline-01"
            color="gold-300">
            {{ title }}
          </ai-typo>
          <div
            v-for="(item, index) of items"
            :key="`AiAccordion-${item.label}`"
            @click="currentAccordionIndex = index">
            <ai-accordion-item
              v-model="currentAccordionIndex"
              :active="currentAccordionIndex === index"
              :label="item.label"
              :expanded-component="item.expandedComponent"
              :expanded-component-props="item.expandedComponentProps" />
            <ai-separator
              class="AiAccordion-separator"
              color="stratos"
              :margin="1" />
          </div>
        </grid-item>
        <grid-item class="AiAccordion-buttonContent" offset-md="3" md="1">
          <ai-carousel-button
            aria-hidden="true"
            :hidden="true"
            vertical
            @top-click="previousAccordion"
            @bottom-click="nextAccordion" />
        </grid-item>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AiAccordionItem from './AiAccordionItem.vue';

export type AiAccordionItems = {
  label?: string;
  expandedComponent?: Component | keyof HTMLElementTagNameMap;
  expandedComponentProps: Record<string, unknown>;
};

type Props = {
  title?: string;
  items: AiAccordionItems[];
  modelValue: number;
};

type Emits = {
  (event: 'update:modelValue', value: number): void;
};

const props = withDefaults(defineProps<Props>(), {
  title: '',
});

const emit = defineEmits<Emits>();

const currentAccordionIndex = computed({
  get() {
    return props.modelValue;
  },
  set(value: number) {
    emit('update:modelValue', value);
  },
});

const previousAccordion = () => {
  if (currentAccordionIndex.value <= 0) return;
  currentAccordionIndex.value--;
};

const nextAccordion = () => {
  if (currentAccordionIndex.value >= props.items.length - 1) return;
  currentAccordionIndex.value++;
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/mq';

.AiAccordion-title {
  text-transform: uppercase;
  margin-bottom: constants.$inner-06;
  text-align: center;

  @media (mq.$from-medium) {
    text-align: start;
  }
}

.AiAccordion-buttonContent {
  display: none;
  @media (mq.$from-medium) {
    display: flex;
    align-items: center;
  }
}

.AiAccordion-separator {
  @media (mq.$from-medium) {
    display: none;
  }
}
</style>
