<template>
  <ai-button
    class="AiAccordionItem-labelBlock"
    variant="tertiary"
    :aria-expanded="active"
    :aria-controls="`content-` + slugify(label)">
    <ai-typo
      class="AiAccordionItem-label"
      :class="[{ 'AiAccordionItem-label--active': active }]"
      as="h3"
      :variant="labelTypo"
      :color="labelColor">
      {{ label }}
    </ai-typo>
    <div
      class="AiAccordionItem-icon"
      :class="{ 'AiAccordionItem-icon--active': active }">
      <ai-icon
        class="AiAccordionItem-icon--chevron"
        name="chevron-down"
        :size="{ height: 24, width: 24 }" />
    </div>
  </ai-button>
  <component
    :is="expandedComponent"
    v-show="active"
    :id="`content-` + slugify(label)"
    :title="label"
    v-bind="expandedComponentProps" />
</template>

<script setup lang="ts">
import type { AiTypoVariant, TypoColor } from '../../atoms/AiTypo/types';
import AiAccordionItemContent from '../../molecules/AiAccordionItemContent/AiAccordionItemContent.vue';

import { slugify } from '~~/helpers';

type Props = {
  label?: string;
  active?: boolean;
  modelValue: number;
  expandedComponent: Component | keyof HTMLElementTagNameMap;
  expandedComponentProps: Record<string, unknown>;
};

const props = withDefaults(defineProps<Props>(), {
  active: false,
  expandedComponent: AiAccordionItemContent,
  label: '',
});

const labelColor = computed<TypoColor>(() =>
  props.active ? 'gold-300' : 'white',
);

const labelTypo = computed<AiTypoVariant>(() =>
  props.active ? 'heading-02' : 'heading-04',
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';

.AiAccordionItem-labelBlock {
  display: flex;
  height: auto;
  margin-bottom: constants.$margin-02;
  justify-content: space-between;
  cursor: pointer;
  transition: all 500ms;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media (mq.$from-medium) {
    justify-content: initial;
    margin-bottom: constants.$margin-01;
  }
}

.AiAccordionItem-label {
  transition: all 500ms;
  text-align: start;

  &--active {
    display: flex;
    align-items: center;

    @media (mq.$from-medium) {
      &::before {
        content: '';
        position: relative;
        @include mixins.rem-fallback(width, 64);
        @include mixins.rem-fallback(height, 1);
        margin-right: constants.$margin-01;

        border: 1px solid colors.$gold-300;
      }
    }
  }
}

.AiAccordionItem-icon {
  color: colors.$white;
  display: flex;
  align-items: center;
  margin-left: constants.$margin-01;

  transition: all 300ms;
  transform: rotate(0deg);

  &--active {
    color: colors.$gold-300;
    transform: rotate(-180deg);
  }
}
</style>
