<template>
  <ai-accordion
    v-if="items.length"
    v-model="currentAccordionIndex"
    :title="title"
    :items="items" />
</template>

<script setup lang="ts">
import type { AiAccordionItems } from './AiAccordion.vue';
import AiAccordion from './AiAccordion.vue';

import type { PillarFragment } from '~~/domains/graphql';

type Props = {
  title?: string;
  modelValue: number;
  items: PillarFragment[];
};

type Emits = {
  (event: 'update:modelValue', value: number): void;
};

const props = withDefaults(defineProps<Props>(), {
  title: '',
});

const emit = defineEmits<Emits>();

const items = computed<AiAccordionItems[]>(
  () =>
    props.items?.map<AiAccordionItems>(item => ({
      expandedComponentProps: {
        description: item.description,
        link: item.link,
        picture: item.picture,
      },
      label: item.label ?? '',
    })) || [],
);

const currentAccordionIndex = computed({
  get() {
    return props.modelValue;
  },
  set(value: number) {
    emit('update:modelValue', value);
  },
});
</script>
