<template>
  <ai-loyalty-banner :title="title" md-logo="2" md-content="10">
    <template #mainContent>
      <ul>
        <li class="AiHomeLoyaltyBanner-item">
          <ai-icon :size="18" name="check-mark" />

          <ai-typo as="p" variant="paragraph-04-regular">
            <ai-typo as="strong" variant="paragraph-04-bold">
              {{ $t('homepage.loyaltyBanner.boldText1') }}
            </ai-typo>
            {{ $t('homepage.loyaltyBanner.text1') }}
          </ai-typo>
        </li>

        <li class="AiHomeLoyaltyBanner-item">
          <ai-icon :size="18" name="check-mark" />

          <ai-typo as="p" variant="paragraph-04-regular">
            <ai-typo as="strong" variant="paragraph-04-bold">
              {{ $t('homepage.loyaltyBanner.boldText2') }}
            </ai-typo>
            {{ $t('homepage.loyaltyBanner.text2') }}
          </ai-typo>
        </li>

        <li class="AiHomeLoyaltyBanner-item">
          <ai-icon :size="18" name="check-mark" />

          <ai-typo as="p" variant="paragraph-04-regular">
            <ai-typo as="strong" variant="paragraph-04-bold">
              {{ $t('homepage.loyaltyBanner.boldText3') }}
            </ai-typo>
            {{ $t('homepage.loyaltyBanner.text3') }}
          </ai-typo>
        </li>

        <li class="AiHomeLoyaltyBanner-item">
          <ai-icon :size="18" name="check-mark" />

          <ai-typo as="p" variant="paragraph-04-regular">
            <ai-typo as="strong" variant="paragraph-04-bold">
              {{ $t('homepage.loyaltyBanner.boldText4') }}
            </ai-typo>
            {{ $t('homepage.loyaltyBanner.text4') }}
          </ai-typo>
        </li>
      </ul>

      <ai-typo
        class="AiHomeLoyaltyBanner-moreLabel"
        as="p"
        variant="paragraph-04-regular">
        {{ $t('hotel.loyalty.label') }}
      </ai-typo>
    </template>

    <template #actionContent>
      <div v-if="!logged" class="AiHomeLoyaltyBanner-button">
        <ai-button
          as-link
          :url="signupUrl"
          class="AiHomeLoyaltyBanner-memberButton"
          :label="$t('hotel.loyalty.footer.memberButtonLabel')"
          variant="secondary"
          :aria-label="$t('accessibility.description.becomeMemberButton')"
          @click="sendCtaEnrollEvent" />

        <div class="AiHomeLoyaltyBanner-loginButton">
          <ai-typo
            class="AiHomeLoyaltyBanner-loginButtonLabel"
            as="p"
            variant="paragraph-04-regular">
            {{ $t('hotel.loyalty.footer.label') }}
          </ai-typo>

          <ai-button
            as-link
            :url="loginUrl"
            slim
            :label="$t('hotel.loyalty.footer.logButtonLabel')"
            variant="tertiary"
            :aria-label="$t('accessibility.description.loginButton')" />
        </div>
      </div>

      <div v-else>
        <ai-typo as="p" variant="paragraph-04-regular">
          {{ $t('hotel.loyalty.footer.not') }}
        </ai-typo>

        <ai-button
          slim
          :label="$t('hotel.loyalty.footer.logOutButtonLabel')"
          variant="tertiary"
          :aria-label="$t('accessibility.description.disconnectButton')"
          @click="logout" />
      </div>
    </template>
  </ai-loyalty-banner>
</template>

<script setup lang="ts">
import { useUserStore } from '~~/domains/user';
import { getRedirectUrl } from '~~/domains/ux/helpers/loyaltyBanner';
import { event } from '~~/helpers/gtm.helper';

const config = useRuntimeConfig();
const route = useRoute();
const userStore = useUserStore();
const { t, locale } = useI18n();

const signupUrl = computed(() =>
  getRedirectUrl(
    config.public.accorAuthenticationUrl,
    config.public.baseUrl,
    locale.value,
    {
      redirect: route.fullPath.toString(),
      type: 'signup',
    },
    true,
  ).toString(),
);

const loginUrl = computed(() =>
  getRedirectUrl(
    config.public.accorAuthenticationUrl,
    config.public.baseUrl,
    locale.value,
    {
      redirect: route.fullPath.toString(),
      type: 'login',
    },
  ).toString(),
);

const logout = () => {
  userStore.logout();
};

const logged = computed(() => userStore.isLogged);

const title = computed<string>(() =>
  logged.value
    ? t('homepage.loyaltyBanner.loggedTitle')
    : t('homepage.loyaltyBanner.notLoggedTitle'),
);

const sendCtaEnrollEvent = () => {
  event('cta_enroll', {
    event_name: 'cta_enroll',
    enroll_context: 'loyalty',
    pagename: 'homepage',
  });
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';

.AiIcon--check-mark {
  margin-right: constants.$margin-00;
}

.AiHomeLoyaltyBanner-item {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: constants.$inner-02;

  @media (mq.$from-medium) {
    text-align: start;
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: constants.$margin-01;

    @media (mq.$from-medium) {
      margin-bottom: constants.$margin-02;
    }
  }
}

.AiHomeLoyaltyBanner-moreLabel {
  margin-bottom: constants.$margin-04;
  text-align: center;

  @media (mq.$from-medium) {
    text-align: start;
  }
}

.AiHomeLoyaltyBanner-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (mq.$from-medium) {
    flex-direction: row;
  }

  @media (mq.$medium) {
    align-items: flex-end;
  }
}

.AiHomeLoyaltyBanner-memberButton {
  width: 100%;
  margin-bottom: constants.$margin-02;

  @media (mq.$from-medium) {
    width: auto;
    margin-right: constants.$margin-02;
    margin-bottom: 0;
  }
}

.AiHomeLoyaltyBanner-loginButton {
  text-align: center;

  @media (mq.$from-large) {
    text-align: start;
  }
}

.AiHomeLoyaltyBanner-loginButtonLabel {
  margin-bottom: constants.$inner-02;

  @media (mq.$medium) {
    margin-bottom: 0;
  }
}
</style>
